const React = require('react');

const { useUserInfo } = require('@forbes/fbs-components/dist/cjs/components/GlobalHeader/hooks/useUserInfo');

const {
	fireGAEvent,
} = require('../../shared/trackingUtils');
const { lGet } = require('../../shared/storageUtils');

const { useEffect, useState } = React;

const useGlobalHeader = ({
	meta,
	isFda,
	isProd,
	isNewsletter,
	isUs,
}) => {
	const [canManageWallet, setCanManageWallet] = useState(false);
	const { isSubscriber } = useUserInfo();

	useEffect(() => {
		setCanManageWallet(!!lGet('fbs_web3'));
	}, []);

	const currentUrl = meta?.url || meta?.canonicalUrl || 'https://www.forbes.com/';
	const baseUrl = `https://${isProd ? 'account.forbes.com' : 'staging-account.forbes.com'}`;

	const forceButtonDisplay = isFda || (!isNewsletter && !isSubscriber);

	const track = (category, action, label) => {
		fireGAEvent({
			eventCategory: category,
			eventAction: action,
			eventLabel: label,
		});
	};

	const searchClickHandler = () => {
		fireGAEvent({
			eventAction: 'click',
			eventLabel: 'globalnav-search-icon-click',
		});
	};

	const menuButtonTrackingProps = {
		track,
		category: 'navs',
		action: 'click-hamburgericon',
		label: 'hamburger-menu-icon',
	};

	const newsLetterSubscribeTrackingProps = {
		track,
		category: 'Template Area Interaction',
		action: 'click',
		label: 'newsletter-subscribe-navigation',
	};

	const loginButtonTrackingProps = {
		track,
		category: 'Template Area Interaction',
		action: 'Click',
		label: 'U20-SignIn',
	};

	const subscribeBtnHandler = () => {
		window.fbq?.('track', 'InitiateCheckout');
		window.gtag?.('event', 'conversion', {
			send_to: 'AW-10801493403/xbiFCIeOwZkYEJvrxp4o',
		});
	};

	let subscribeButtonText;
	let subscribeButtonUrl;
	let trackingLabel;
	let className;
	let subscribeLinkTarget = '_blank';
	let subscribeLinkRel = 'noopener noreferrer';

	if (isFda) {
		subscribeButtonUrl = '/digital-assets/community/';
		subscribeButtonText = canManageWallet ? 'Manage Wallet' : 'Connect Wallet';
		subscribeLinkTarget = '_self';
		subscribeLinkRel = null;
		trackingLabel = canManageWallet ? 'fda-globalnav-managemywallet-click' : 'fda-globalnav-cta-wallet-click';
		className = canManageWallet ? 'manage-wallet' : null;
	} else {
		subscribeButtonUrl = `${baseUrl}/subscribe?eventSource=header&redirect=${currentUrl}`;
		trackingLabel = 'U20-Subscribe';
	}

	const subscribeBtnProps = {
		'data-ga-track': trackingLabel,
		className,
		isCustomSubscribe: isFda,
		rel: subscribeLinkRel,
		subscriptionBtnText: subscribeButtonText,
		subscribeUrl: subscribeButtonUrl,
		target: subscribeLinkTarget,
		isUs,
		onClick: subscribeBtnHandler,
	};

	return {
		currentUrl,
		forceButtonDisplay,
		loginButtonTrackingProps,
		menuButtonTrackingProps,
		newsLetterSubscribeTrackingProps,
		searchClickHandler,
		subscribeBtnProps,
	};
};

module.exports = {
	useGlobalHeader,
};
